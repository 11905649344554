//
// Footer
//

// General mode
.footer-p {
  p {
    padding: 0;
    margin: 0;
  }
}